import dialogs from '../stores/dialogs'
import EmployeeMenu from '../components/EmployeeMenu.svelte'
import { currentBundle } from '../stores/bundle'
import { employeeLanguage } from '../stores/i18n'
import { get } from 'svelte/store'
import { selfUserInfo } from './api'

export function isEmployeeMenuOpen () {
  return get(dialogs).find(dialog => dialog.Component === EmployeeMenu)
}

export async function openEmployeeMenu (withDocBrown = false) {
  recordEmployeeActivity()
  if (isEmployeeMenuOpen()) return
  window.dispatchEvent(new CustomEvent('employeeMenuOpened'))
  return await dialogs.open(EmployeeMenu, { withDocBrown })
}

export async function closeEmployeeMenu () {
  const { id } = isEmployeeMenuOpen()
  if (id) dialogs.close(id)
}

let employeeActivityTimeout
export function recordEmployeeActivity () {
  if (employeeActivityTimeout) clearTimeout(employeeActivityTimeout)
  employeeActivityTimeout = setTimeout(() => {
    console.log('Employee activity timeout')
    window.dispatchEvent(new CustomEvent('employeeMenuTimeout'))
  }, selfUserInfo?.role === 'employee' ? 3600000 : 10000)
}

window.addEventListener('employeeMenuTrigger', () => {
  openEmployeeMenu()
})

window.addEventListener('nfcTag', event => {
  const card = currentBundle?.settings?.employeeCards?.find(card => card.cardId === event.detail.id)
  if (card) {
    console.log('Employee card detected:', card)
    if (card.language) employeeLanguage.set(card.language)
    openEmployeeMenu(window.appVariables.configParams.debug.docBrownAccessTagId && window.appVariables.configParams.debug.docBrownAccessTagId === event.detail.id)
  } else {
    console.log('Unknown card detected:', event.detail.id)
    window.dispatchEvent(new CustomEvent('unknownNfcTag', { detail: event.detail }))
  }
})

// Ctrl+E = employee menu
window.addEventListener('keydown', event => {
  if (event.ctrlKey && event.key === 'e') {
    event.preventDefault()
    openEmployeeMenu()
  }
})
