import { get } from 'svelte/store'
import EventEmitter from 'eventemitter3'
import * as devalue from 'devalue'
import dialogs from '../stores/dialogs'
import Reloading from '../components/Reloading.svelte'
import ImageZoomer from '../components/ImageZoomer.svelte'
import { currentCustomerLanguage, currentEmployeeLanguage } from '../stores/i18n'
import { Text } from 'slate'
import { createLoadingStore } from '../stores/loading'
import { apiCall } from './api'
import qrcode from 'qrcode'

export const withProcessingOverlay = createLoadingStore()

if (!window.$yo) window.$yo = {}
window.$yo.devalue = devalue

export function delay (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function escape (s, nl2br = false) {
  if (!s) return s
  const i = document.createElement('i')
  i.innerText = s
  return nl2br ? i.innerHTML.replace(/\n/g, '<br>') : i.innerHTML
}

export function removeUndefined (object) { // Note that this does mutate the object
  for (const [k, v] of Object.entries(object)) {
    if (v === undefined) delete object[k]
  }
  return object
}

const ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
export function generateRandomString (length) {
  let s = ''
  for (let i = 0; i < length; i++) {
    s += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length))
  }
  return s
}

// Wraps an event handler to trigger only on native events
export function onlyNative (handler) {
  return event => {
    if (event instanceof window.CustomEvent) return
    return handler(event)
  }
}

// Wraps an event handler to trigger only on custom events
// For example, to trigger on Svelma's custom input events that happen after bound value was already updated!
export function onlyCustom (handler) {
  return event => {
    if (!(event instanceof window.CustomEvent)) return
    return handler(event)
  }
}

export function setupStoreGuard (store, guard) {
  const { set, update } = store

  store.set = value => {
    let prev
    // This has to be called anyway, unfortunately, otherwise `$store = value` will break in weird ways
    update(old => {
      prev = old
      return value
    })

    try {
      const finalValue = guard(value, prev)
      if (finalValue !== prev) set(finalValue)
    } catch (e) {
      set(prev)
      throw e
    }
  }

  store.update = updater => {
    store.set(updater(get(store)))
  }
}

export function makeStoreEventEmitter (store) {
  Object.setPrototypeOf(store, EventEmitter.prototype)
  EventEmitter.call(store)
}

export function autoSubscribe (onMount, eventEmitter, event, handler) {
  onMount(() => {
    eventEmitter.on(event, handler)
    return () => eventEmitter.off(event, handler)
  })
}

export function autoFocusInput (node, { delay = 0 }) {
  setTimeout(() => node.querySelector('input, textarea').focus(), delay)
}

export function escapeRegexp (str) {
  return str.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d')
}

export function getAddressShortLabel (address) {
  return `${address.slice(0, 5)}…${address.slice(-3)}`
}

export function formatError (error) {
  if (!error) return undefined
  if (typeof error === 'string') return error
  if (error.message) return error.message
  if (error.stack && String(error) !== '[object Object]') return String(error)
  return JSON.stringify(error)
}

export function round2 (num) {
  return Number(Number(num).toFixed(2))
}

export function showReloadingOverlay () {
  if (!get(dialogs).some(dialog => dialog.Component === Reloading)) dialogs.open(Reloading)
}

export function reloadPage (hard = false) {
  showReloadingOverlay()

  window.location.reload(hard)
}

export function getMediaUrl (data, size) {
  if (!data) return data
  const sizeObj = data.sizes?.[size]
  const sizeUrl = sizeObj?.url ?? (sizeObj?.filename ? `/media/${sizeObj.filename}` : undefined)
  return sizeUrl ?? data.url ?? `/media/${data.filename}`
}

export function isVideo (filename) {
  if (filename.filename) filename = filename.filename
  return !!filename.match(/\.(mp4|m4v)$/i)
}

export async function preloadImage (src) {
  return new Promise((resolve, reject) => {
    const img = new window.Image()
    img.onload = resolve
    img.onerror = reject
    img.src = src
  })
}

export async function doZoomImage (event, src = event?.target.closest('img')?.src) {
  if (get(dialogs).some(d => d.Component === ImageZoomer)) return
  await dialogs.open(ImageZoomer, { src, thumbnailElement: event?.target })
}

export function formatCurrency (value, isEmployee) {
  return new Intl.NumberFormat(`${isEmployee ? currentEmployeeLanguage : currentCustomerLanguage}-${window.appVariables.configParams.localization.country}`, {
    style: 'currency',
    currency: window.appVariables.configParams.localization.currency,
    minimumFractionDigits: 2
  }).format(value)
}

export function formatTime (value, isEmployee) { // value is something like '14_30' or '14:30'
  return new Intl.DateTimeFormat(`${isEmployee ? currentEmployeeLanguage : currentCustomerLanguage}-${window.appVariables.configParams.localization.country}`, {
    hour: 'numeric',
    minute: 'numeric'
  }).format(new Date(2000, 0, 1, ...value.split(/[:_]/).map(Number)))
}

export function formatMinutesSeconds (ms) {
  // mm:ss
  const minutes = Math.floor(ms / 60000)
  const seconds = Math.floor((ms % 60000) / 1000)
  return `${minutes}:${String(seconds).padStart(2, '0')}`
}

export function handleOverscroll (element) {
  element.classList.add('overscroll')

  const getStartOffset = () => parseFloat(window.getComputedStyle(element, ':before').height) || 0
  const getEndOffset = () => Math.max(element.scrollHeight - element.clientHeight - parseFloat(window.getComputedStyle(element, ':after').height) || 0, getStartOffset())

  element.scrollTo({
    top: getStartOffset(),
    behavior: 'instant'
  })

  const listener = () => {
    if (element.classList.contains('has-overscroll-disabled')) return

    if (element.scrollTop < getStartOffset()) {
      element.scrollTo({
        top: getStartOffset(),
        behavior: 'smooth'
      })
    } else if (element.scrollTop > getEndOffset()) {
      element.scrollTo({
        top: getEndOffset(),
        behavior: 'smooth'
      })
    }
  }

  element.addEventListener('scrollend', listener, { passive: true })

  return {
    destroy: () => element.removeEventListener('scrollend', listener)
  }
}

export function getDescriptionPreview (description, maxLength = 200) {
  // description is a Slate children array
  // Find enough text nodes to fill maxLength characters (recursively), then leave it. Interpret block-type elements as newlines.
  let remaining = maxLength
  let preview = ''
  const stack = [...description ?? []]
  while (stack.length) {
    const node = stack.shift()
    if (Text.isText(node)) {
      if (node.text.length <= remaining) {
        preview += node.text
        remaining -= node.text.length
      } else {
        preview += node.text.slice(0, remaining)
        remaining = 0
      }
    } else if (preview && !preview.endsWith('\n')) {
      preview += '\n'
      remaining--
    }
    if (!node.type && node.children) stack.unshift(...node.children) // The check for node.type ignores any special elements like headers
    if (remaining === 0) break
  }
  if (stack.length) preview = preview.slice(0, -1) + '…'
  return preview
}

export function timeRestrictionMatches ({ timeRestriction, timeRestrictionStart, timeRestrictionEnd }) {
  if (!timeRestriction || !timeRestrictionStart || !timeRestrictionEnd) return true
  // Check local time. Restriction uses HH_MM format. End < Start is allowed if the restriction passes midnight.
  const now = new Date()
  const nowMinutes = now.getHours() * 60 + now.getMinutes()
  const [startH, startM] = timeRestrictionStart.split('_').map(Number)
  const startMinutes = startH * 60 + startM
  const [endH, endM] = timeRestrictionEnd.split('_').map(Number)
  const endMinutes = endH * 60 + endM
  return (startMinutes <= nowMinutes && nowMinutes < endMinutes) || (startMinutes >= nowMinutes && nowMinutes > endMinutes)
}

export function rippleOnTouch (element) {
  const listener = e => {
    if (!e.changedTouches?.length) return

    // Abort if a child is already rippling and the event originated within that child.
    // We could also use stopPropagation on the child but then the font zoom pinch gesture wouldn't work anymore!
    for (const r of element.querySelectorAll('.ripple-container')) {
      const rippler = r.parentElement
      if (rippler !== element && rippler.contains(e.target)) return
    }

    const rippleContainer = document.createElement('span')
    rippleContainer.classList.add('ripple-container')
    element.appendChild(rippleContainer)
    const ripple = document.createElement('span')
    ripple.classList.add('ripple')
    rippleContainer.appendChild(ripple)
    const x = e.changedTouches[0].screenX - element.getBoundingClientRect().left
    const y = e.changedTouches[0].screenY - element.getBoundingClientRect().top
    ripple.style.left = `${x}px`
    ripple.style.top = `${y}px`

    setTimeout(() => {
      rippleContainer.remove()
    }, 300)
  }

  element.addEventListener('touchstart', listener, { passive: true })

  return {
    destroy: () => element.removeEventListener('touchstart', listener)
  }
}

export function isRichTextNodeEmpty (child) {
  if (child.type) return false
  if (Text.isText(child)) return !child.text.trim()
  if (child.children) return child.children.every(isRichTextNodeEmpty)
  return true
}

export function noopScroll (element) {
  if (!element) return
  element.scrollTo(element.scrollLeft, element.scrollTop)
  element.dispatchEvent(new Event('scrollend'))
}

export function validateArticleConfiguration (articleConfiguration, $bundle, tableType = 'regular') {
  const article = $bundle.articles[articleConfiguration?.article]

  const finalPrice = !!article &&
    !article.isPage &&
    articleConfiguration.subArticles.every(({ article }) => $bundle.articles[article]?.posArticle) &&
    round2(article.posArticle.price + articleConfiguration.subArticles.reduce((sum, { article, quantity }) => sum + round2($bundle.articles[article].posArticle.price * quantity), 0))

  const isValid = !!article &&
    !article.isPage &&
    article.mergedGroups.every(group => group.effectiveSelectionMode === 'multiple' || articleConfiguration.subArticles.filter(subArticle => subArticle.subArticleGroupId === group.id).length === 1) &&
    article.available &&
    $bundle.categories.some(category => category.available && category.articles.includes(article)) &&
    articleConfiguration.subArticles.every(({ article }) => $bundle.articles[article]?.available) &&
    !(tableType === 'kiosk' && article.type === 'main' && (article?.takeawayAvailability === 'hidden' || article?.takeawayAvailability === 'disabledWithInfo'))

  return { finalPrice, isValid }
}

export async function sendToServerLog (logLevel, text, type, context, error, metadata) {
  try {
    await apiCall('POST', '/api/app/log', {
      logLevel,
      text,
      type,
      context,
      metadata: {
        ...error
          ? {
              name: error.name,
              message: error.message,
              code: error.code,
              stack: error.stack,
              ...typeof error === 'string' ? { error } : { ...error }
            }
          : {},
        ...metadata
      }
    })
  } catch (e) {
    console.error('Failed to send log entry:', e)
  }
}

export function appendToBody (element) {
  document.body.appendChild(element)
}

export function renderQrCode (element, { url }) {
  qrcode.toCanvas(element, url, { width: 208, margin: 4 }, (err) => {
    if (err) console.error('Failed to render QR code', err)
  })
}
