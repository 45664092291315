<script>
  import ModalDialog from './ModalDialog.svelte'
  import { tE } from '../stores/i18n'
  import { Button, Dialog, Input } from 'svelma-fixed'
  import { createEventDispatcher } from 'svelte'
  import Loader from './Loader.svelte'
  import { apiCall } from '../lib/api'
  import counterBundle from '../stores/counterBundle'
  import { getProcessNumber } from '../../shared/lib/misc'
  import dialogs from '../stores/dialogs'
  import OrderDetailsDialog from './OrderDetailsDialog.svelte'
  import { formatCurrency } from '../lib/utils'
  import html from 'html-template-tag'

  const dispatch = createEventDispatcher()

  export let tableNumber = null
  let searchValue = ''
  let fetchPromise = fetchOrders()

  $: if (tableNumber) searchValue = 'T' + tableNumber

  function load () {
    fetchPromise = fetchOrders()
  }

  function close () {
    dispatch('close')
  }

  async function fetchOrders () {
    try {
      return await apiCall('GET', '/api/app/allOrders')
    } catch (e) {
      console.error('Error fetching orders:', e)
      throw e
    }
  }

  function isMatching (searchValue, order) {
    searchValue = searchValue.replace(/\W/g, '').toLowerCase()
    if (!searchValue) return true

    const tableMatching = v => $counterBundle.tables[order.table]?.number === Number(v)
    const processMatching = v => Number(getProcessNumber(order.session.id)) === Number(v)
    const receiptMatching = v => order.receiptNumbers?.map(Number).includes(Number(v))
    const invoiceMatching = v => Number(order.invoiceNumber) === Number(v)

    if (searchValue.startsWith('t')) return tableMatching(searchValue.slice(1))
    if (searchValue.startsWith('p')) return processMatching(searchValue.slice(1))
    if (searchValue.startsWith('r')) return receiptMatching(searchValue.slice(1))
    if (searchValue.startsWith('i')) return invoiceMatching(searchValue.slice(1))

    return tableMatching(searchValue) || processMatching(searchValue) || receiptMatching(searchValue) || invoiceMatching(searchValue)
  }

  function statusLegendClick () {
    Dialog.alert({
      message: html`
        <i class="fa fa-fw fa-credit-card has-text-primary"></i> = ${$tE({ de: 'Bezahlt am Gerät', en: 'Paid on Device' })}<br />
        <i class="fa fa-fw fa-cash-register has-text-primary"></i> = ${$tE({ de: 'Bezahlt an der Theke', en: 'Paid at Counter' })}<br />
        <i class="fa fa-fw fa-share has-text-primary"></i> = ${$tE({ de: 'Bestellung verbucht', en: 'Order Booked' })}<br />
        <i class="fa fa-fw fa-receipt has-text-primary"></i> = ${$tE({ de: 'Rechnung verbucht', en: 'Invoice Booked' })}<br />
        <i class="fa fa-fw fa-check-square has-text-primary"></i> = ${$tE({ de: 'Fertig', en: 'Ready' })}<br />
        <i class="fa fa-fw fa-exclamation-triangle has-text-danger"></i> = ${$tE({ de: 'Fehler', en: 'Error' })}
      `,
      type: 'is-info',
      icon: 'info-circle',
      size: 'is-large'
    })
  }

  async function openOrderDetails (order) {
    const result = await dialogs.open(OrderDetailsDialog, { order })
    if (result?.ready) load()
  }
</script>

<style lang="scss">
  :global(.order-management-dialog):has(input.search-input:focus) {
    padding-bottom: 50vh;
  }

  main {
    .big-buttons {
      gap: 0.25em;
    }

    .order-row {
      display: grid;
      grid-template-columns: 1.25fr 1fr 1fr 1fr 1fr 1.25fr 2fr;
      gap: 0.5em;
      width: 100%;

      &.legend {
        font-size: 0.8em;
        padding: 0.75em calc(1.71875em + 2px);
        text-align: center;

        a {
          text-decoration: underline;
        }
      }

      > * {
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:not(.legend) {
        font-size: 1.5em;

        .order-table {
          font-weight: bold;
        }

        .order-receipt {
          font-weight: bold;
        }
      }
    }
  }
</style>

<svelte:window on:employeeMenuTimeout={close} />

<ModalDialog --width="95vw" class="employee-ui no-auto-top-half order-management-dialog">
  <main>
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title is-1">{$tE({ de: 'Bestellungen', en: 'Orders' })}</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <Button class="big-button semi-big tight" type="is-secondary" on:click={load} iconLeft="refresh">{$tE({ de: 'Neu laden', en: 'Reload' })}</Button>
        </div>
        <div class="level-item">
          {#if tableNumber}
            <Button class="big-button semi-big tight" type="is-dark" on:click={close} iconLeft="arrow-left">{$tE({ de: 'Zurück', en: 'Back' })}</Button>
          {:else}
            <Button class="big-button semi-big tight" type="is-dark" on:click={close} iconLeft="times">{$tE({ de: 'Schließen', en: 'Close' })}</Button>
          {/if}
        </div>
      </div>
    </div>

    <Input expanded class="search-input" type="search" bind:value={searchValue} placeholder={$tE({ de: 'Tischnr./Vorgangsnr./Bestellnr./Rechnungsnr. suchen...', en: 'Search table/process/order/invoice number...' })} disabled={!!tableNumber} icon="search" />

    {#await fetchPromise}
      <Loader inline />
    {:then orders}
      {#if orders?.length}
        <div class="order-row legend">
          <div class="order-time">{$tE({ de: 'Zeit', en: 'Time' })}</div>
          <div class="order-table">{$tE({ de: 'Tisch', en: 'Table' })}</div>
          <div class="order-process">{$tE({ de: 'Vorgang', en: 'Process' })}</div>
          <div class="order-receipt">{$tE({ de: 'Bestellnr.', en: 'Order No.' })}</div>
          <div class="order-invoice">{$tE({ de: 'Rechnungsnr.', en: 'Invoice No.' })}</div>
          <div class="order-amount">{$tE({ de: 'Betrag', en: 'Amount' })}</div>
          <div class="order-status"><a href={undefined} on:click={statusLegendClick}>{$tE({ de: 'Status', en: 'Status' })}</a></div>
        </div>
      {/if}

      <div class="big-buttons">
        {#each (orders ?? []).filter(o => isMatching(searchValue, o)) as order}
          <button class="button is-secondary order-row" on:click={() => openOrderDetails(order)}>
            <div class="order-time">{new Date(order.createdAt).toLocaleTimeString()}</div>
            <div class="order-table">{$counterBundle.tables[order.table]?.number ?? '???'}</div>
            <div class="order-process" class:has-text-light-grey={$counterBundle.tables[order.table]?.type !== 'kiosk'}>{getProcessNumber(order.session.id)}</div>
            <div class="order-receipt">{order.receiptNumbers?.join('/') ?? ''}</div>
            <div class="order-invoice">{order.invoiceNumber ?? ''}</div>
            <div class="order-amount">{$tE && formatCurrency(order.totalAmount, true)}</div>
            <div class="order-status">
              <i class="fa fa-credit-card {order.paidOnDevice ? 'has-text-primary' : 'has-text-light-grey'}"></i>
              <i class="fa fa-cash-register {order.paidAtCounter ? 'has-text-primary' : 'has-text-light-grey'}"></i>
              <i class="fa fa-share {order.bookedOrder ? 'has-text-primary' : 'has-text-light-grey'}"></i>
              <i class="fa fa-receipt {order.bookedInvoice ? 'has-text-primary' : 'has-text-light-grey'}"></i>
              <i class="fa fa-check-square {order.ready ? 'has-text-primary' : 'has-text-light-grey'}"></i>
              <i class="fa fa-exclamation-triangle {order.error ? 'has-text-danger' : 'has-text-light-grey'}"></i>
            </div>
          </button>
        {:else}
          <p>{$tE({ de: 'Keine Bestellungen gefunden', en: 'No orders found' })}</p>
        {/each}
      </div>
    {:catch error}
      <h2 class="title is-2 has-text-danger">{$tE({ de: 'Fehler beim Laden der Bestellungen', en: 'Error loading orders' })}</h2>
    {/await}
  </main>
</ModalDialog>
