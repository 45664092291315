<script>
  import { createEventDispatcher } from 'svelte'
  import { tC } from '../stores/i18n'
  import { formatCurrency } from '../lib/utils'
  import HelpCircle from 'svelte-feather/components/HelpCircle.svelte'
  import BrandButton from './BrandButton.svelte'
  import ModalDialog from './ModalDialog.svelte'

  const dispatch = createEventDispatcher()

  export let tip
  export let tipPercentage
  export let total
</script>

<style lang="scss">
  main {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    :global(svg) {
      width: 3rem;
      height: 3rem;
      stroke-width: 1;
    }

    span {
      white-space: pre-wrap;
    }
  }
</style>

<ModalDialog title={$tC({ de: 'Frage', en: 'Question' })} closeable on:close={() => dispatch('close', [false])}>
  <main class="mb-4">
    <span class='has-text-primary'><HelpCircle/></span>
    <span>
        {$tC({ de: 'Sie haben ein Trinkgeld von {tip} eingegeben, was ungewöhnlich hoch ({tipPercentage}%) für eine Rechnung von {total} ist. Ist das korrekt?', en: 'You entered a tip of {tip} which is unusually high ({tipPercentage}%) for a {total} bill. Is this correct?' }, { tip: formatCurrency(tip), tipPercentage, total: formatCurrency(total) })}
    </span>
  </main>

  <div class="has-text-right">
    <BrandButton class="is-primary" on:click={() => dispatch('close', [true, tip])}>{$tC({ de: 'Ja', en: 'Yes' })}</BrandButton>
    <BrandButton on:click={() => dispatch('close', [false])}>{$tC({ de: 'Nein, zurückgehen', en: 'No, go back' })}</BrandButton>
    <!-- Otherwise, it's possible to enter negative tip -->
    {#if tip > total}
      <BrandButton on:click={() => dispatch('close', [true, tip - total])}>{$tC({ de: 'Nein, {tip} ist der Gesamtbetrag', en: 'No, {tip} is the total amount' }, { tip: formatCurrency(tip) })}</BrandButton>
    {/if}
  </div>
</ModalDialog>
