<script>
  import { tC } from '../stores/i18n'
  import ModalDialog from './ModalDialog.svelte'
  import BrandButton from './BrandButton.svelte'
  import { createEventDispatcher } from 'svelte'
  import { session } from '../stores/session'
  import { createLoadingStore } from '../stores/loading'
  import dialogs from '../stores/dialogs'
  import PhoneNumberDialog from './PhoneNumberDialog.svelte'
  import EmailDialog from './EmailDialog.svelte'
  import { apiCall } from '../lib/api'
  import uri from 'uri-tag'
  import MessageDialog from './MessageDialog.svelte'
  import Check from 'svelte-feather/components/Check.svelte'
  import AlertTriangle from 'svelte-feather/components/AlertTriangle.svelte'
  import MessageCircle from 'svelte-feather/components/MessageCircle.svelte'
  import ShoppingCart from 'svelte-feather/components/ShoppingCart.svelte'
  import Mail from 'svelte-feather/components/Mail.svelte'
  import { Toast } from 'svelma-fixed'
  import bundle from '../stores/bundle'
  import { formatCurrency, renderQrCode } from '../lib/utils'

  export let order

  const dispatch = createEventDispatcher()

  const sendingSms = createLoadingStore()
  const sendingEmail = createLoadingStore()
  const sending = { sms: sendingSms, email: sendingEmail }
  async function sendInvoice (method) {
    const data = await dialogs.open(method === 'sms' ? PhoneNumberDialog : EmailDialog, $session.userData)
    if (!data) return

    $session.userData = Object.assign($session.userData, data)

    try {
      await sending[method](async () => {
        await apiCall('POST', uri`/api/app/sendInvoice/${method}`, { orderId: order.id, userData: $session.userData })
      })
    } catch (e) {
      console.error('Failed to send invoice', e)

      await dialogs.open(MessageDialog, { title: $tC({ de: 'Senden fehlgeschlagen', en: 'Sending Failed' }), text: $tC({ de: 'Leider ist beim Absenden der Rechnung ein Problem aufgetreten! Bitte kontrollieren Sie Ihre Eingabe und versuchen Sie es erneut!', en: 'Unfortunately, there was a problem sending the invoice! Please check your input and try again!' }), iconClass: 'has-text-danger', Icon: AlertTriangle })

      return
    }

    Toast.create({ message: $tC({ de: 'Rechnung wurde erfolgreich versendet!', en: 'Invoice was sent successfully!' }), type: 'is-success', duration: 6000 })
  }

</script>

<style lang="scss">
  main {
    width: calc(100vw - 100px);

    h4 {
      margin-bottom: 0.5rem;
    }

    .column {
      padding-top: 0;
      padding-bottom: 0;
    }

    .invoice-col {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      p {
        margin-bottom: 0;
      }
    }

    canvas {
      margin: auto;
      margin-top: -0.5rem;
      box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    }

    .paper-invoice {
      width: 18rem;
      margin: auto;
      line-height: 1;
      font-size: 0.8rem;
      text-align: center;
    }
  }

  .cart-area {
    max-height: calc(88vh - 16rem);
    overflow-y: auto;

    article:nth-child(odd) .cart-row {
      background: #f2f2f2;
    }

    article:nth-child(even) .cart-row {
      background: #fcfcfc;
    }

    .cart-row {
      display: flex;
      gap: 0.25rem;
      align-items: center;
      padding: 0.5rem 0;
      padding-right: 0.5rem;
      border-top: 1px solid #ccc;
      min-height: 2.75em;

      &.sub-article {
        padding-left: 2rem;
      }

      &.comment {
        padding-left: 5rem;
        font-style: italic;
      }

      .quantity {
        flex: 0 0 2rem;
        text-align: right;
      }

      .name {
        flex: 1;
      }

      .price {
        flex: 0 0 6rem;
        text-align: right;
      }

      .add-to-cart {
        flex: 0 0 3rem;
        text-align: right;

        :global(button) {
          margin: -0.5em 0;
          margin-left: 1em;
        }

        :global(svg) {
          transform: scale(1.5) translate(0.01em, -0.01em) !important;
        }
      }
    }

    article:last-child .cart-row {
      border-bottom: 1px solid #ccc;
    }
  }
</style>

<ModalDialog title={$tC({ de: 'Vorige Bestellung', en: 'Previous Order' })} closeable on:close>
  <main class="columns">
    <div class="column is-7">
      <h4 class="title is-4">{$tC({ de: 'Bestellung Nr. {n} ({price}):', en: 'Order No. {n} ({price}):' }, { n: order.receiptNumbers?.join('/') || '---', price: formatCurrency(order.totalAmount) })}</h4>
      {#if order.error}
        <div class="notification is-danger">{$tC({ de: 'Leider ist bei der Bestellung ein Fehler aufgetreten. Bitte wenden Sie sich an einen Mitarbeiter!', en: 'Unfortunately, there was an error with the order. Please contact a member of staff!' })}</div>
      {:else}
      <div class="cart-area">
          {#each order.articles as item}
            <article>
              <div class="cart-row">
                <div class="quantity">{item.quantity}&times;</div>
                <div class="name">{$tC($bundle.articles[item.article].name ?? { de: 'Unbekannt', en: 'Unknown' })}</div>
                <div class="price">{$tC && formatCurrency($bundle.articles[item.article]?.posArticle?.price * item.quantity, true)}</div>
                <div class="add-to-cart">
                  <BrandButton icon on:click={() => dispatch('close', { addToCartItem: item })}><ShoppingCart /></BrandButton>
                </div>
              </div>
            </article>

            {#each item.subArticles as subArticleInfo}
              <article>
                <div class="cart-row sub-article">
                  <div class="quantity">{subArticleInfo.quantity * item.quantity}&times;</div>
                  <div class="name">{$tC($bundle.articles[subArticleInfo.article].name ?? { de: 'Unbekannt', en: 'Unknown' })}</div>
                  <div class="price">{$tC && formatCurrency($bundle.articles[subArticleInfo.article]?.posArticle?.price * subArticleInfo.quantity * item.quantity, true)}</div>
                  <div class="add-to-cart"></div>
                </div>
              </article>
            {/each}

            {#if item.comment}
              <div class="cart-row comment">
                <div class="comment">
                  {item.comment}
                </div>
              </div>
            {/if}
          {/each}

          {#if order.tipAmount}
            <article>
              <div class="cart-row">
                <div class="quantity"><i class="fa fa-beer"></i></div>
                <div class="name">{$tC({ de: 'Trinkgeld', en: 'Tip' })}</div>
                <div class="price">{$tC && formatCurrency(order.tipAmount, true)}</div>
                <div class="add-to-cart"></div>
              </div>
            </article>
          {/if}
        </div>
      {/if}
    </div>
    <div class="column is-5 invoice-col">
      <h4 class="title is-4">{$tC({ de: 'Ihre Rechnung:', en: 'Your Invoice:' })}</h4>
      {#if order.invoiceUrl}
        <canvas use:renderQrCode={{ url: order.invoiceUrl }}></canvas>

        <div class="button-container">
          <BrandButton class="is-fullwidth" on:click={() => sendInvoice('sms')} loading={$sendingSms}><MessageCircle /> {$tC({ de: 'Rechnung per SMS erhalten', en: 'Get Invoice via SMS' })}</BrandButton>
        </div>
        <div class="button-container">
          <BrandButton class="is-fullwidth" on:click={() => sendInvoice('email')} loading={$sendingEmail}><Mail /> {$tC({ de: 'Rechnung per E-Mail erhalten', en: 'Get Invoice via Email' })}</BrandButton>
        </div>
        <p class="paper-invoice" >{$tC({ de: 'Für eine Rechnung in Papierform wenden Sie sich bitte an einen Mitarbeiter.', en: 'For a paper invoice, please contact a member of staff.' })}</p>
      {:else}
        <p>{$tC({ de: 'Die Rechnung für diese Bestellung ist leider nicht digital verfügbar. Für eine Rechnung in Papierform wenden Sie sich bitte an einen Mitarbeiter.', en: 'The invoice for this order is not available digitally. For a paper invoice, please contact a staff member.' })}</p>
      {/if}
    </div>
  </main>

  <hr />

  <BrandButton class="is-fullwidth is-primary" on:click={() => dispatch('close')}><Check /> {$tC({ de: 'Fertig', en: 'Done' })}</BrandButton>
</ModalDialog>
