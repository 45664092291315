<script>
  import { tC } from '../stores/i18n'
  import BrandButton from './BrandButton.svelte'
  import ModalDialog from './ModalDialog.svelte'
  import { Field, Input } from 'svelma-fixed'
  import { createEventDispatcher, tick } from 'svelte'
  import Check from 'svelte-feather/components/Check.svelte'
  import { formatCurrency } from '../lib/utils'
  import dialogs from '../stores/dialogs'
  import LargeTipDialog from './LargeTipDialog.svelte'

  const dispatch = createEventDispatcher()

  // The appropriate decimal point depends on the customer language retrieved from i18n store.
  // The value doesn't need to be reactive. We access the store via reactive `$` operator for for brevity and readability.
  const decimalPoint = $tC({ de: ',', en: '.' })

  export let userVal = ''
  export let billAmount

  let formEl
  let mode = 'tip' // 'tip' | 'total'

  $: calcValue = mode === 'tip' ? calcTotal(userVal, billAmount) : calcTip(userVal, billAmount)

  async function submit () {
    let toBeTipped = mode === 'tip' ? Number(userVal.replace(',', '.')) : calcValue

    if (toBeTipped < 0) return

    const tipPercentage = toBeTipped / billAmount

    if (tipPercentage >= 0.3) {
      const [proceed, tip] = await dialogs.open(LargeTipDialog, { tip: toBeTipped, tipPercentage: Math.ceil(tipPercentage * 100), total: billAmount })
      if (!proceed) return

      toBeTipped = tip
    }

    dispatch('close', toBeTipped)
  }

  async function handleInput (event) {
    const input = event.detail?.target ?? event.target
    const oldVal = input.value
    const oldCaretPos = input.selectionStart

    const newVal = oldVal
      .replace(/[^\d.,*#]/gi, '') // Clear all disallowed characters.
      .replace(/[#*,.]/g, decimalPoint) // Hashes, asterisks, commas, and dots all mean the same thing - decimal point (dot for EN, comma for DE).
      .replace(decimalPoint, 'x') // Replace the first decimal point with a tmp value.
      .replaceAll(decimalPoint, '') // More than one decimal point is not allowed. Delete all decimal points (tmp value is intact).
      .replace('x', decimalPoint) // Switch the tmp value back to a decimal point.

    if (newVal !== oldVal) {
      userVal = newVal

      await tick()

      const charRemoved = newVal.length < oldVal.length && newVal.slice(0, oldCaretPos) !== oldVal.slice(0, oldCaretPos)
      const newCaretPos = charRemoved ? oldCaretPos - 1 : oldCaretPos

      input.setSelectionRange(newCaretPos, newCaretPos)
    }
  }

  function calcTip (userValue, billAmount) {
    return Number(userValue.replace(',', '.')) - Number(billAmount)
  }

  function calcTotal (userValue, billAmount) {
    return Number(billAmount) + Number(userValue.replace(',', '.'))
  }
</script>

<style lang="scss">
  .tip-buttons {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      margin-bottom: 0.75rem;
  }

  .tip-calculation {
    margin-top: 0.75rem;
    font-size: 1.35em;
  }

  form {
    :global(.control) {
      font-size: 2rem;
      width: 100%;
      position: relative;

      :global(input) {
        text-align: center;
        font-size: 1em;
      }

      &::after {
        content: '€';
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        color: #cccccc;
      }
    }
  }
</style>

<ModalDialog --width="18rem" --min-width="18rem" class="dialog-top-half" title={$tC({ de: 'Trinkgeld eingeben', en: 'Enter Tip' })} closeable on:close on:introend={formEl.querySelector('input').focus()}>
  <div class="tip-buttons">
    <BrandButton class="is-fullwidth" selected={mode === 'tip'} on:click={() => { mode = 'tip' }}>
      {$tC({ de: 'Trinkgeld eingeben', en: 'Enter Tip' })}
    </BrandButton>
    <BrandButton class="is-fullwidth" selected={mode === 'total'} on:click={() => { mode = 'total' }}>
      {$tC({ de: 'Gesamtbetrag eingeben', en: 'Enter Total Amount' })}
    </BrandButton>
  </div>

  <form on:submit|preventDefault={submit} bind:this={formEl}>
    <Field>
      <Input expanded type="text" bind:value={userVal} on:input={handleInput} pattern="[0-9.,]*" inputmode="decimal" min="0" placeholder="0.00" />
    </Field>

    <BrandButton type="submit" class="is-fullwidth is-primary" disabled={ calcValue < 0 }><Check /> {$tC({ de: 'OK', en: 'OK' })}</BrandButton>
  </form>

  <div class="tip-calculation">
    <p>{$tC({ de: 'Rechnungsbetrag', en: 'Bill amount' })}: <strong>{formatCurrency(billAmount)}</strong></p>
    {#if !isNaN(calcValue)}
      {#if mode === 'tip'}
        <p>{$tC({ de: 'Gesamtbetrag', en: 'Total Amount' })}: <strong>{formatCurrency(calcValue)}</strong></p>
      {:else if calcValue > 0}
        <p>{$tC({ de: 'Trinkgeldbetrag', en: 'Tip Amount' })}: <strong>{formatCurrency(calcValue)}</strong></p>
      {/if}
    {/if}
  </div>
</ModalDialog>
