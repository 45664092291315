<script>
  import ModalDialog from './ModalDialog.svelte'
  import { tE } from '../stores/i18n'
  import { Button, Dialog, Toast } from 'svelma-fixed'
  import { createEventDispatcher } from 'svelte'
  import { formatCurrency, withProcessingOverlay } from '../lib/utils'
  import { getOptionLabel, tableStatuses } from '../../payload/i18nConstants'
  import { apiCall } from '../lib/api'
  import counterBundle, { updateCounterBundle } from '../stores/counterBundle'
  import bundle from '../stores/bundle'
  import html from 'html-template-tag'
  import uri from 'uri-tag'
  import { getCartTotal } from '../stores/session'
  import OrderManagementDialog from './OrderManagementDialog.svelte'
  import dialogs from '../stores/dialogs'
  import { createLoadingStore } from '../stores/loading'
  import PayAtCounterDialog from './PayAtCounterDialog.svelte'

  export let tableId

  $: table = $counterBundle.tables[tableId]

  const dispatch = createEventDispatcher()
  const robotCalling = createLoadingStore()
  let robotCalled = false

  function close () {
    dispatch('close')
  }

  $: if (!table) close()

  async function tableAction (table, action) {
    if (action === 'release') {
      const message = table.status === 'reserved'
        ? $tE({ de: 'Dieser Tisch ist aktuell für einen wartenden Kunden reserviert. Wenn Sie den Tisch jetzt freigeben, muss der Kunde zur Theke kommen und ihm muss händisch ein neuer Tisch zugewiesen werde! Fortfahren?', en: 'This table is currently reserved for a waiting customer. If you release the table now, the customer has to come to the counter and a new table has to be assigned to them manually! Proceed?' })
        : $tE({ de: 'Sind Sie sicher, dass Sie den Tisch freigeben möchten?', en: 'Are you sure you want to release the table?' })

      if (!await Dialog.confirm({
        message,
        type: 'is-warning',
        icon: 'exclamation-triangle',
        confirmText: $tE({ de: 'Ja', en: 'Yes' }),
        cancelText: $tE({ de: 'Nein', en: 'No' }),
        size: 'is-large'
      })) return
    }

    await withProcessingOverlay(async () => {
      try {
        await apiCall('POST', uri`/api/app/tableAction/${table.id}/${action}`)

        await updateCounterBundle()
        close()
      } catch (e) {
        Dialog.alert({
          message: html`
            <p class="mb-3">
              ${$tE({ de: 'Fehler beim Ausführen der Aktion:', en: 'Error executing action:' })}
            </p>
            <p>
              ${e.serverErrorMessage ?? e.message}
            </p>
          `,
          type: 'is-danger',
          icon: 'exclamation-circle'
        })
      }
    })
  }

  async function callRobot () {
    await robotCalling(async () => {
      try {
        await apiCall('POST', '/api/app/callRobot', { tableId: table.id })
        Toast.create({ message: $tE({ de: 'Roboter wurde gerufen!', en: 'Robot has been called!' }), type: 'is-success' })
        robotCalled = true
      } catch (e) {
        Dialog.alert({
          message: html`
            <p class="mb-3">
              <strong class="has-text-danger">${$tE({ de: 'Konnte Roboter nicht rufen!', en: 'Could not call robot!' })}</strong>
            </p>
            <p>
              <small>${e.code === 'robot_unavailable' || e.serverErrorMessage?.includes('614920') ? $tE({ de: 'Roboter nicht verfügbar! Eventuell bereits in Verwendung.', en: 'Robot not available! Maybe already in use.' }) : e.serverErrorMessage ?? e.message}</small>
            </p>
          `,
          size: 'is-large',
          type: 'is-danger',
          icon: 'exclamation-circle'
        })
      }
    })
  }

  async function cleaningAction (table, action) {
    if (!['start', 'finish'].includes(action)) throw new Error('Invalid table cleaning action')

    if (action === 'start' && table.status !== 'free') {
      const message = table.status === 'reserved'
        ? $tE({ de: 'Dieser Tisch ist aktuell für einen wartenden Kunden reserviert. Wenn Sie die Reinigung jetzt starten, wird der Tisch freigegeben, und der Kunde muss zur Theke kommen, um einen neuen Tisch manuell zugewiesen zu bekommen! Fortfahren?', en: 'This table is currently reserved for a waiting customer. If you start cleaning now, the table will be released and the customer will have to come to the counter for a new table to be assigned manually! Proceed?' })
        : $tE({ de: 'Das Starten der Reinigung wird den Tisch freigeben. Fortfahren?', en: 'Starting cleaning will release the table. Proceed?' })

      if (!await Dialog.confirm({
        message,
        type: 'is-warning',
        icon: 'exclamation-triangle',
        confirmText: $tE({ de: 'Ja', en: 'Yes' }),
        cancelText: $tE({ de: 'Nein', en: 'No' }),
        size: 'is-large'
      })) return
    }

    await withProcessingOverlay(async () => {
      await apiCall('POST', `/api/app/${action}Cleaning`, { tableId: table.id })
      await updateCounterBundle()
    })
  }

  async function setAgeVerification (ageVerified) {
    await withProcessingOverlay(async () => {
      await apiCall('POST', '/api/app/setAgeVerification', { sessionId: table.currentSession.id, ageVerified })
      await updateCounterBundle()
    })
  }
</script>

<style lang="scss">
  :global(.columns) > .cart-column:where(.cart-column)[class] { // Increase specificity to override the default column styles
    padding-left: 0.5em !important;
    margin-top: -3.25em !important;
  }

  .cart-area {
    max-height: 16.25rem;
    overflow-y: auto;
  }
</style>

<ModalDialog --width="95vw" class="employee-ui">
  {#if table}
    <main>
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title is-1">{$tE({ de: 'Tisch', en: 'Table' })} {table ? `${table.number} (${$tE(getOptionLabel(tableStatuses, table.status))})` : '---'}</h1>
          </div>
        </div>
        <div class="level-right is-align-self-flex-start has-text-danger">
          {#if table.blockQueued}
            <div class="level-item">
              (wird blockiert)
            </div>
          {/if}
        </div>
      </div>

      <div class="columns is-gapless">
        <div class="column is-9">
          <div class="big-buttons">
            <Button class="big-button" type="is-dark" on:click={close} iconLeft="times">{$tE({ de: 'Schließen', en: 'Close' })}</Button>

            {#if table.status !== 'disabled'}
              {#if table.status === 'blocked' || table.blockQueued}
                <Button class="big-button" type="is-success" on:click={() => tableAction(table, 'unblock')} iconLeft="lock-open">{$tE({ de: 'Blockierung aufheben', en: 'Unblock' })}</Button>
              {:else}
                <Button class="big-button" type="is-danger" on:click={() => tableAction(table, 'block')} iconLeft="lock">{$tE({ de: 'Blockieren', en: 'Block' })}{table.status === 'free' ? '' : $tE({ de: ' (sobald frei)', en: ' (once free)' })}</Button>
              {/if}

              {#if (table.status === 'free' || table.status === 'blocked') && table.type !== 'kiosk'}
                <Button class="big-button" type="is-secondary" on:click={() => tableAction(table, 'assign')} iconLeft="user-check">{$tE({ de: 'An Kunde vergeben', en: 'Assign to Customer' })}</Button>
              {:else if table.status === 'occupied' || table.status === 'reserved'}
                <Button class="big-button" type="is-warning" disabled={table.currentSession?.uiState.currentView === 'payment' && table.currentSession?.uiState.paymentState === 'processing'} on:click={() => tableAction(table, 'release')} iconLeft="people-arrows">{$tE({ de: 'Freigeben', en: 'Release' })}</Button>
              {:else if table.status === 'cleaning'}
                <Button class="big-button" type="is-success" on:click={() => cleaningAction(table, 'finish')} iconLeft="hand-sparkles">{$tE({ de: 'Fertig gereinigt', en: 'Done Cleaning' })}</Button>
              {/if}
              {#if table.status === 'free' || table.status === 'occupied'}
                <Button class="big-button" type="is-primary" disabled={table.currentSession?.uiState.currentView === 'payment' && table.currentSession?.uiState.paymentState === 'processing'} on:click={() => cleaningAction(table, 'start')} iconLeft="broom">{$tE({ de: 'Reinigung', en: 'Cleaning' })}</Button>
              {/if}
            {/if}

            {#if table.robotPointId && $bundle.settings.powerSwitch.robotsEnabled}
              <Button class="big-button" type="is-info" on:click={callRobot} loading={$robotCalling} disabled={robotCalled} iconLeft="robot">{$tE({ de: 'Roboter rufen', en: 'Call Robot' })}</Button>
            {/if}

            {#if table.type !== 'kiosk' && $counterBundle.payAtCounterSessions.some(session => session.id === table.currentSession?.id)}
              <Button class="big-button" type="is-success" on:click={() => dialogs.open(PayAtCounterDialog, { sessionId: table.currentSession.id })} iconLeft="cash-register">{$tE({ de: 'Jetzt bezahlen', en: 'Pay Now' })} ({$tE && formatCurrency(getCartTotal(table.currentSession.cart, $bundle), true)})</Button>
            {:else if table.status === 'occupied' && table.currentSession?.cart?.length && (table.currentSession?.uiState.currentView !== 'payment' || !['success', 'payAtCounter'].includes(table.currentSession?.uiState.paymentState))}
              <Button class="big-button" type="is-secondary" disabled={table.currentSession?.uiState.currentView === 'payment' && (table.currentSession?.uiState.paymentState === 'processing')} on:click={() => tableAction(table, 'payAtCounter')} iconLeft="cash-register">{$tE({ de: 'An der Theke bezahlen', en: 'Pay at Counter' })}</Button>
            {/if}

            {#if table.currentSession && table.type !== 'kiosk'}
              <Button class="big-button" type="is-secondary" on:click={() => setAgeVerification(!table.currentSession.userData.ageVerified)} iconLeft="cocktail">{$tE({ de: 'Alter 18+ OK', en: 'Age 18+ OK' })} [{#if table.currentSession.userData.ageVerified}<strong class="has-text-success">{$tE({ de: 'JA', en: 'YES' })}</strong>{:else if table.currentSession.userData.ageVerified == null}<strong class="has-text-warning">?</strong>{:else}<strong class="has-text-danger">{$tE({ de: 'NEIN', en: 'NO' })}</strong>{/if}]</Button>
            {/if}

            <Button class="big-button" type="is-secondary" on:click={() => dialogs.open(OrderManagementDialog, { tableNumber: table.number })} iconLeft="shopping-cart">{$tE({ de: 'Vorige Bestellungen', en: 'Previous Orders' })}</Button>
          </div>
        </div>

        <div class="column is-3 cart-column">
          <h4 class="title is-4">{$tE({ de: 'Warenkorb', en: 'Cart' })} ({$tE && formatCurrency(getCartTotal(table.currentSession?.cart ?? [], $bundle), true)})</h4>
          <div class="cart-area">
            {#if table.currentSession?.uiState?.currentView === 'payment'}
              <div class="cart-item">
                {#if table.currentSession?.uiState?.paymentState !== 'success'}
                  <strong class="has-text-danger">{$tE({ de: 'In Zahlungsprozess', en: 'In payment process' })}</strong>
                {:else}
                  <strong class="has-text-success">{$tE({ de: 'Bestellung abgeschlossen', en: 'Order completed' })}</strong>
                {/if}
              </div>
            {/if}
            {#each table.currentSession?.cart ?? [] as item}
              <div class="cart-item">
                {item.quantity}&times; {$tE($bundle.articles[item.article]?.name ?? { de: 'Unbekannt', en: 'Unknown' })}
              </div>
              {#each item.subArticles as subArticleInfo}
                <div class="cart-item">
                  &nbsp;+ {subArticleInfo.quantity * item.quantity}&times; {$tE($bundle.articles[subArticleInfo.article]?.name ?? { de: 'Unbekannt', en: 'Unknown' })}
                </div>
              {/each}
              {#if item.comment}
                <div class="cart-item">
                  &nbsp;* {item.comment}
                </div>
              {/if}
            {:else}
              <div class="cart-item">
                {$tE({ de: 'Keine Artikel', en: 'No items' })}
              </div>
            {/each}
          </div>
        </div>
      </div>
    </main>
  {/if}
</ModalDialog>
