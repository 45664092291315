import { derived, readable, writable } from 'svelte/store'
import AsyncLock from 'async-lock'
import { apiCall } from '../lib/api'
import { executeWhenNotCritical } from './critical'
import { reloadPage } from '../lib/utils'
import session, { currentSession, resetSession } from './session'
import { localState } from './localState'
import bundle from './bundle'

const lock = new AsyncLock()

export const table = writable(null)
export default table

export let currentTable
table.subscribe($table => {
  if (!window.$yo) window.$yo = {}
  window.$yo.table = $table

  currentTable = $table
})

window.addEventListener('backendWsMessage', event => {
  if (event.detail.type === 'tableDate' && new Date(event.detail.tableDate ?? 0).valueOf() !== new Date(currentTable?.updatedAt ?? 0).valueOf()) {
    executeWhenNotCritical('updateTable', () => updateTable())
  } else if (event.detail.type === 'queueStats' && currentTable?.type === 'kiosk') {
    table.update($table => Object.assign($table, { queueStats: event.detail.queueStats }))
  }
})

export async function updateTable (initial = false) {
  if (isFixedTestTableMode) return

  await lock.acquire('updateTable', async () => {
    console.log('Updating table')

    let newTable = await apiCall('GET', '/api/app/currentTable')
    if (!newTable.id) newTable = null
    console.log('Table:', newTable)

    if (newTable) localState.update($localState => Object.assign($localState, { testTableFlag: false }))

    if (currentTable?.id === newTable?.id && newTable?.robotTaskId && newTable?.robotTaskId !== 'queued' && (!currentTable?.robotTaskId || currentTable?.robotTaskId === 'queued')) {
      console.log('Robot task initiated, updating lastRobotCall as well')
      session.update($session => Object.assign($session, { lastRobotCall: new Date().toISOString() }))
    }

    if (!initial && (
      !!newTable !== !!currentTable ||
      newTable?.id !== currentTable?.id ||
      newTable?.number !== currentTable?.number ||
      newTable?.type !== currentTable?.type ||
      newTable?.posTable !== currentTable?.posTable ||
      newTable?.kioskFeatures?.join(',') !== currentTable?.kioskFeatures?.join(',') ||
      (newTable?.status !== currentTable?.status && (newTable.status === 'disabled' || (['free', 'occupied', 'reserved'].includes(newTable?.status) && !['free', 'occupied', 'reserved'].includes(currentTable?.status)))) ||
      newTable?.currentSession !== currentTable?.currentSession ||
      newTable?.deviceId !== currentTable?.deviceId ||
      newTable?.reloadCounter !== currentTable?.reloadCounter
    )) {
      console.log('Table update requires reload')
      executeWhenNotCritical('reload', () => reloadPage())
    } else {
      table.set(newTable)

      if (!newTable?.currentSession && currentSession.id) resetSession()
    }
  })
}

export const isFixedTestTableMode = new URLSearchParams(window.location.search).has('testTable')

export const isTestTableMode = isFixedTestTableMode ? readable(true) : derived([table, localState], ([$table, $localState]) => !$table && !!$localState?.testTableFlag)

export const isOff = derived([table, bundle, isTestTableMode], ([$table, $bundle, $isTestTableMode]) => !$isTestTableMode && (!$table || (!['occupied', 'reserved', 'cleaning'].includes($table.status) && $bundle.settings.powerSwitch.mode === 'off')))
export const isEvent = derived([table, bundle, isTestTableMode], ([$table, $bundle, $isTestTableMode]) => !$isTestTableMode && $bundle.settings.powerSwitch.mode === 'event' && $table && !['occupied', 'reserved'].includes($table.status) && ($table.type !== 'kiosk' || !$bundle.settings.eventSettings?.takeAwayOnDuringEvent))

export const isMenuPreviewMode = derived([table, bundle, isOff], ([$table, $bundle, $isOff]) => $bundle.settings.kioskMenuPreview && $isOff && !!$table)
