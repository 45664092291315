// From https://github.com/omer-g/persistent-svelte-store
// Modified for some fixes and support for sessionStorage

/** A generic persistent store according to the Svelte store contract
 *
 *  @example
 *      import { persistentWritable } from "./persistentStore";
 *      export const store = persistentWritable<object>("storeKey", {});
 *
 *      $store = { id: 1 };
 *      console.log($store.id);
 *
 *  @template T - Should be a type JSON.stringify can process
 *  @param {string} storeKey - A unique key in localStorage for the store.
 *                             This will also be the channel name in Broadcast API.
 *  @param {T} initialValue - Initial value of store
 *  @param {boolean} useSessionStorage - Whether session storage or local storage should be used (default = false)
 *  @returns {PersistentWritable<T>} - A persistent writable store
 */
export const persistentWritable = (storeKey, initialValue, useSessionStorage = false) => {
  let subscriptions = []
  let storeValue

  const storage = useSessionStorage ? window.sessionStorage : window.localStorage

  const safeParse = (jsonString) => {
    try {
      return JSON.parse(jsonString)
    } catch (error) {
      if (error instanceof Error) {
        console.log(error)
      }
    }
  }

  const safeSetItem = (key, value) => {
    try {
      storage.setItem(key, JSON.stringify(value))
    } catch (error) {
      if (error instanceof Error) {
        console.log(error)
      }
    }
  }

  const safeGetItem = (key) => {
    try {
      return storage.getItem(key)
    } catch (error) {
      if (error instanceof Error) {
        console.log(error)
      }
    }
  }

  const currentStoreString = safeGetItem(storeKey)
  if (currentStoreString === null || currentStoreString === undefined) {
    storeValue = initialValue
    safeSetItem(storeKey, storeValue)
  } else {
    storeValue = safeParse(safeGetItem(storeKey))
  }

  let storeChannel = new BroadcastChannel(storeKey)
  storeChannel.onmessage = event => {
    storeValue = safeParse(safeGetItem(storeKey))
    if (event.data === storeKey) {
      subscriptions.forEach(subscriptions => subscriptions(storeValue))
    }
  }

  // Subscribes function and returns an unsubscribe function
  const subscribe = (subscription) => {
    subscription(storeValue)
    subscriptions = [...subscriptions, subscription]

    // If subscribers go from 0 to 1 (after dropping to 0 before) recreate channel
    if (subscription.length === 1 && storeChannel === null) {
      storeChannel = new BroadcastChannel(storeKey)
    }
    const unsubscribe = () => {
      subscriptions = subscriptions.filter(s => s !== subscription)

      // If subsccribers go from 1 to 0 close channel
      if (storeChannel && subscription.length === 0) {
        storeChannel.close()
        storeChannel = null
      }
    }
    return unsubscribe
  }

  // Sets stringified value in local storage and calls subscriptions
  const set = (value) => {
    storeValue = value
    safeSetItem(storeKey, value)
    subscriptions.forEach(subscription => subscription(storeValue))

    if (storeChannel) {
      setTimeout(() => storeChannel.postMessage(storeKey), 1) // Without the timeout, we somehow got old data broadcasted when derived writable stores were involved
    }
  }

  // Updates store value according to input function
  const update =
    (updateFunc) => set(updateFunc(storeValue))
  return { subscribe, set, update }
}
