<script>
  import ModalDialog from './ModalDialog.svelte'
  import { tE } from '../stores/i18n'
  import { Button, Dialog, Input } from 'svelma-fixed'
  import { createEventDispatcher } from 'svelte'
  import counterBundle, { updateCounterBundle } from '../stores/counterBundle'
  import dialogs from '../stores/dialogs'
  import QueueItemDetailsDialog from './QueueItemDetailsDialog.svelte'
  import { formatMinutesSeconds, sendToServerLog } from '../lib/utils'
  import bundle from '../stores/bundle'
  import { getLedTargetSetting, getSignalColor } from '../../shared/lib/ledColors'
  import { SUSPENDED_QUEUE_INDEX, getProcessNumber } from '../../shared/lib/misc'
  import { second, minute } from '../stores/timer'
  import { createLoadingStore } from '../stores/loading'
  import { apiCall } from '../lib/api'
  import html from 'html-template-tag'
  import QueueNewCustomerDialog from './QueueNewCustomerDialog.svelte'

  const queueRegistering = createLoadingStore()

  const dispatch = createEventDispatcher()

  let searchValue = ''

  function close () {
    dispatch('close')
  }

  function isMatching (searchValue, session) {
    const matchOnlyQueueNo = searchValue.toLowerCase().startsWith('w')
    searchValue = searchValue.replace(/\D/g, '').toLowerCase()
    if (!searchValue) return true

    const queueNoMatching = v => session.queueNo === Number(v)
    const phoneNumberMatching = v => session.userData?.phoneNumber.includes(v.replace(/^0/, ''))

    if (matchOnlyQueueNo) return queueNoMatching(searchValue)

    return queueNoMatching(searchValue) || phoneNumberMatching(searchValue)
  }

  async function openQueueItemDetails (queueItem) {
    await dialogs.open(QueueItemDetailsDialog, { queueItemId: queueItem.id })
  }

  async function queueNewCustomer () {
    await queueRegistering(async () => {
      let data
      try {
        if ($counterBundle.queueStats.availableTableCount > 0) {
          if (!await Dialog.confirm({
            message: $tE({ de: 'Wirklich kein Tisch frei?', en: 'Is really no table available?' }),
            type: 'is-info',
            icon: 'question-circle',
            confirmText: $tE({ de: 'Nichts frei, weiter', en: 'Nothing free, continue' }),
            cancelText: $tE({ de: 'Abbrechen', en: 'Cancel' }),
            size: 'is-large'
          })) return
        }

        data = await dialogs.open(QueueNewCustomerDialog)
        if (!data) return

        const { ok, estimatedWaitingTime, queueNo } = await apiCall('POST', '/api/app/registerForTableQueue', { userData: data, priority: data.priority })

        if (ok) {
          Dialog.alert({
            message: html`
              <p class="mb-3">
                ${$tE({ de: 'Kunde erfolgreich registriert.', en: 'Customer successfully registered.' })}
              </p>
              $${data.phoneNumber
                ? html`
                  <p>
                    ${$tE({ de: 'Kunde erhält eine SMS an:', en: 'Customer will get a text to:' })} <strong>**${data.phoneNumber.slice(-3)}</strong>
                  </p>
                `
                : html`
                  <p>
                    ${$tE({ de: 'Wartenummer:', en: 'Queue number:' })} <strong style="font-size: 200%;">W${queueNo}</strong>
                  </p>
                `}
              $${estimatedWaitingTime && html`
                <p class="mb-3">
                  ${$tE({ de: 'Voraussichtliche Wartezeit:', en: 'Estimated waiting time:' })} <strong>${estimatedWaitingTime} ${$tE({ de: 'Min.', en: 'min.' })}</strong>
                </p>
              `}
            `,
            type: 'is-success',
            icon: 'check-circle',
            size: 'is-large'
          })
        } else {
          throw new Error('Non-OK response from server for table registration')
        }
      } catch (e) {
        console.error('Queue error', e, data)
        if (e.code === 'already_queued') {
          Dialog.alert({
            message: html`
              <p>
                ${$tE({ de: 'Handynummer ist bereits in der Warteschlange!', en: 'Phone number is already in the queue!' })}
              </p>
            `,
            type: 'is-warning',
            icon: 'exclamation-triangle',
            size: 'is-large'
          })
        } else {
          sendToServerLog('error', `Customer queue registration (at counter) failed for ${data.phoneNumber ?? '(no number)'}: ${e}`, 'queueError', 'queue', e, { data })

          Dialog.alert({
            message: html`
              <p class="mb-3">
                ${$tE({ de: 'Fehler beim Ausführen der Aktion:', en: 'Error executing action:' })}
              </p>
              <p>
                ${e.serverErrorMessage ?? e.message}
              </p>
            `,
            type: 'is-danger',
            icon: 'exclamation-circle'
          })
        }
      }

      await updateCounterBundle()
    })
  }
</script>

<style lang="scss">
  :global(.queue-management-dialog):has(input.search-input:focus) {
    padding-bottom: 50vh;
  }

  main {
    .big-buttons {
      gap: 0.25em;
    }

    .queue-row {
      display: grid;
      grid-template-columns: 0.75fr 0.75fr 1.75fr 1fr 2fr;
      gap: 0.5em;
      width: 100%;

      &.legend {
        font-size: 0.8em;
        padding: 0.75em calc(1.71875em + 2px);
        text-align: center;
      }

      > * {
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:not(.legend) {
        font-size: 1.5em;

        .queue-table {
          font-weight: bold;
        }
      }
    }

    .lamp {
      display: inline-block;
      width: calc(1em - 1px);
      height: calc(1em - 1px);
      border-radius: 50%;
      border: 1px solid $grey;
      background: var(--color, transparent);
      vertical-align: middle;
      transform: translateY(-0.1em);

      @keyframes blink {
        0% {
          background-color: grey;
        }
        50% {
          background-color: var(--color, grey);
        }
        100% {
          background-color: grey
        }
      }

      &.blink {
        animation: blink 1s infinite;
      }
    }

  }
</style>

<svelte:window on:employeeMenuTimeout={close} />

<ModalDialog --width="95vw" class="employee-ui no-auto-top-half queue-management-dialog">
  <main>
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title is-1">{$tE({ de: 'Warteschlange', en: 'Table Queue' })}</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <Button disabled={!$bundle.settings.powerSwitch.tableQueueEnabled} class="big-button semi-big tight" type="is-info" on:click={queueNewCustomer} iconLeft="plus" loading={$queueRegistering}>{$tE({ de: 'Kunde registrieren', en: 'Register Customer' })}</Button>
        </div>
        <div class="level-item">
          <Button class="big-button semi-big tight" type="is-dark" on:click={close} iconLeft="times">{$tE({ de: 'Schließen', en: 'Close' })}</Button>
        </div>
      </div>
    </div>

    <Input expanded class="search-input" type="search" bind:value={searchValue} placeholder={$tE({ de: 'Wartenummer/Telefonnummer suchen...', en: 'Search queue/phone number...' })} icon="search" />

    {#if $counterBundle.queuedSessions.length}
      <div class="queue-row legend">
        <div class="queue-time">{$tE({ de: 'Zeit', en: 'Time' })}</div>
        <div class="queue-queue-no">{$tE({ de: 'Wartenummer', en: 'Queue No.' })}</div>
        <div class="queue-phone-number">{$tE({ de: 'Telefonnummer', en: 'Phone Number' })}</div>
        <div class="queue-table">{$tE({ de: 'Tisch / Code', en: 'Table / Code' })}</div>
        <div class="queue-waiting-time">{$tE({ de: 'Restliche Wartezeit / Blockierzeit', en: 'Remaining Waiting Time / Blocking Time' })}</div>
      </div>
    {/if}

    <div class="big-buttons">
      {#each $counterBundle.queuedSessions.filter(s => isMatching(searchValue, s)) as queueItem}
        <button class="button is-secondary queue-row" on:click={() => openQueueItemDetails(queueItem)}>
          <div class="queue-time">{new Date(queueItem.createdAt).toLocaleTimeString()}</div>
          <div class="queue-queue-no" class:has-text-light-grey={!!queueItem.userData.phoneNumber}>W{queueItem.queueNo ?? '???'}</div>
          <div class="queue-phone-number">{queueItem.userData.phoneNumber || '---'}</div>
          <div class="queue-table">
            {#if queueItem.table}
              {queueItem.table.number}
              <div class="lamp" style:--color={getSignalColor(getLedTargetSetting(queueItem.table, $bundle.settings))} class:blink={getLedTargetSetting(queueItem.table).startsWith('!') && window.appVariables.configParams.enableBlinkingLed}></div>
              {#if queueItem.userData.phoneNumber}
                <span style:font-weight="normal">{getProcessNumber(queueItem.id)}</span>
              {/if}
            {:else if queueItem.userData.phoneNumber}
              <span class="has-text-light-grey" style:font-weight="normal">{getProcessNumber(queueItem.id)}</span>
            {:else}
              ---
            {/if}
          </div>
          <div class="queue-waiting-time">
            {#if queueItem.queueIndex === SUSPENDED_QUEUE_INDEX}
              {#if queueItem.table}
                {$second && $tE({ de: 'Reserviert für {t}', en: 'Reserved for {t}' }, { t: formatMinutesSeconds(Math.max(0, (new Date(queueItem.table.signalledSince).valueOf() + $bundle.settings.tableReservedDuration * 60000) - Date.now())) })}
              {:else}
                <strong class="has-text-danger">{$tE({ de: 'Händisch zuweisen!!!', en: 'Assign manually!!!' })}</strong>
              {/if}
            {:else}
              {#if !$counterBundle.queueStats.totalWaitingDuration}
                {$tE({ de: 'Unbekannte Wartezeit', en: 'Unknown waiting time' })}
              {:else}
                {$minute && $tE({ de: 'Noch ca. {min} Min.', en: 'Approx. {min} Min.' }, { min: Math.round($counterBundle.queueStats.baseWaitingDuration + $counterBundle.queueStats.waitingDurationPerQueuedSession * $counterBundle.queuedSessions.filter(s => s.queueIndex < queueItem.queueIndex && s.queueIndex !== SUSPENDED_QUEUE_INDEX).length) })}
              {/if}
            {/if}
          </div>
        </button>
      {:else}
        <p>{$tE({ de: 'Keine wartenden Kunden gefunden', en: 'No waiting customers found' })}</p>
      {/each}
    </div>
  </main>
</ModalDialog>
