<script>
  import ModalDialog from './ModalDialog.svelte'
  import { tE } from '../stores/i18n'
  import { Button, Dialog, Toast } from 'svelma-fixed'
  import { createEventDispatcher } from 'svelte'
  import { reloadPage, withProcessingOverlay } from '../lib/utils'
  import EmployeeLanguageSelector from './EmployeeLanguageSelector.svelte'
  import AssignTableDialog from './AssignTableDialog.svelte'
  import { table, isTestTableMode, isFixedTestTableMode } from '../stores/table'
  import dialogs from '../stores/dialogs'
  import { getOptionLabel, tableStatuses } from '../../payload/i18nConstants'
  import { cart, closeSession, currentSession, newOrder, saveSession } from '../stores/session'
  import EmployeeCardTest from './EmployeeCardTest.svelte'
  import { isYaoKiosk, resetScreen, openGpTom } from '../stores/yaoKiosk'
  import { goto } from 'svelte-pathfinder'
  import { apiCall } from '../lib/api'
  import BatteryStatusAndDeviceId from './BatteryStatusAndDeviceId.svelte'
  import { createLoadingStore } from '../stores/loading'
  import html from 'html-template-tag'
  import bundle from '../stores/bundle'
  import { isEmployeeMenuOpen } from '../lib/employeeMenu'

  export let withDocBrown = false
  const robotCalling = createLoadingStore()
  let robotCalled = false

  const dispatch = createEventDispatcher()

  function close () {
    dispatch('close')
  }

  async function assignTable () {
    await dialogs.open(AssignTableDialog)
  }

  async function callRobot () {
    await robotCalling(async () => {
      try {
        await apiCall('POST', '/api/app/callRobot', { tableId: $table.id })
        Toast.create({ message: $tE({ de: 'Roboter wurde gerufen!', en: 'Robot has been called!' }), type: 'is-success' })
        robotCalled = true
      } catch (e) {
        Dialog.alert({
          message: html`
            <p class="mb-3">
              <strong class="has-text-danger">${$tE({ de: 'Konnte Roboter nicht rufen!', en: 'Could not call robot!' })}</strong>
            </p>
            <p>
              <small>${e.code === 'robot_unavailable' || e.serverErrorMessage?.includes('614920') ? $tE({ de: 'Roboter nicht verfügbar! Eventuell bereits in Verwendung.', en: 'Robot not available! Maybe already in use.' }) : e.serverErrorMessage ?? e.message}</small>
            </p>
          `,
          size: 'is-large',
          type: 'is-danger',
          icon: 'exclamation-circle'
        })
      }
    })
  }

  function employeeCardTest () {
    dialogs.open(EmployeeCardTest)
  }

  async function saveAndReload () {
    try {
      if (currentSession.id) {
        await withProcessingOverlay(async () => {
          await saveSession(false)
        })
      }
    } catch (e) {
      console.error(e)
    }

    reloadPage(true)
  }

  async function finishCleaning () {
    await apiCall('POST', '/api/app/finishCleaning', { tableId: $table.id })
  }

  async function releaseTable (cleaning = false) {
    if (!await Dialog.confirm({
      message: $tE({ de: 'Sind Sie sicher, dass Sie den Tisch freigeben möchten?', en: 'Are you sure you want to release the table?' }),
      type: 'is-warning',
      icon: 'exclamation-triangle',
      confirmText: $tE({ de: 'Ja', en: 'Yes' }),
      cancelText: $tE({ de: 'Nein', en: 'No' }),
      size: 'is-large'
    })) return

    await withProcessingOverlay(async () => {
      await closeSession(cleaning)

      if (!cleaning) {
        reloadPage()
      } else {
        const employeeMenuDialogId = isEmployeeMenuOpen()
        for (const dialog of $dialogs) {
          if (dialog.id !== employeeMenuDialogId) dialogs.close(dialog.id)
        }
      }
    })
  }

  if (isYaoKiosk) resetScreen()
</script>

<svelte:window on:employeeMenuTimeout={close} />

<ModalDialog --width="95vw" class="employee-ui">
  <main>
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title is-1">{$tE({ de: 'Tisch', en: 'Table' })} {$table ? `${$table.number} (${$tE(getOptionLabel(tableStatuses, $table.status))})` : $isTestTableMode ? 'TEST' : '---'}</h1>
        </div>
      </div>
      <div class="level-right">
        <BatteryStatusAndDeviceId class="level-item" />
        <div class="level-item">
          <EmployeeLanguageSelector />
        </div>
      </div>
    </div>

    <div class="big-buttons">
      <Button class="big-button" type="is-dark" on:click={close} iconLeft="times">{$tE({ de: 'Schließen', en: 'Close' })}</Button>

      {#if !$isTestTableMode && $table?.type !== 'kiosk'}
        {#if $table?.status === 'free' || $table?.status === 'occupied'}
          <Button class="big-button {$table?.status === 'free' ? 'double-width' : ''}" type="is-primary" on:click={() => releaseTable(true)} iconLeft="broom">{$tE({ de: 'Reinigung', en: 'Cleaning' })}</Button>
        {:else if $table?.status === 'cleaning'}
          <Button class="big-button double-width" type="is-success" on:click={() => finishCleaning()} iconLeft="hand-sparkles">{$tE({ de: 'Fertig gereinigt', en: 'Done Cleaning' })}</Button>
        {/if}
      {/if}

      {#if $table?.status === 'occupied'}
        <Button class="big-button" type="is-warning" on:click={() => releaseTable(false)} iconLeft="people-arrows">{$tE({ de: 'Tisch freigeben', en: 'Release Table' })}</Button>
      {/if}

      {#if $table?.robotPointId && $bundle.settings.powerSwitch.robotsEnabled}
        <Button class="big-button" type="is-info" on:click={callRobot} loading={$robotCalling} disabled={robotCalled} iconLeft="robot">{$tE({ de: 'Roboter rufen', en: 'Call Robot' })}</Button>
      {/if}

      {#if $cart.length && $table?.type !== 'kiosk'}
        <Button class="big-button" type="is-secondary" on:click={() => { newOrder(); close() }} iconLeft="trash">{$tE({ de: 'Neue Bestellung', en: 'New Order' })}</Button>
      {/if}

      {#if !isFixedTestTableMode}
        <Button class="big-button" type={$table ? 'is-secondary' : 'is-success'} on:click={assignTable} iconLeft="sign-in-alt">{$tE({ de: 'Tisch zuweisen', en: 'Assign Table' })}</Button>
      {/if}

      {#if isYaoKiosk}
        <Button class="big-button" type="is-secondary" on:click={openGpTom} iconLeft="credit-card" iconPack="far">{$tE({ de: 'Karten-Transaktionen', en: 'Credit Card Transactions' })}</Button>
        <Button class="big-button" type="is-secondary" on:click={employeeCardTest} iconLeft="key">{$tE({ de: 'MA-Karten-Test', en: 'Employee Card Test' })}</Button>
      {/if}

      <Button class="big-button" type="is-secondary" on:click={saveAndReload} iconLeft="refresh">{$tE({ de: 'Neu laden', en: 'Reload' })}</Button>

      {#if withDocBrown}
        <Button class="big-button" type="is-secondary" on:click={() => { goto('/doc-brown'); close() }} iconLeft="rocket">{$tE({ de: 'Doc Brown', en: 'Doc Brown' })}</Button>
      {/if}
    </div>
  </main>
</ModalDialog>
