<script>
    import { createEventDispatcher } from 'svelte'
    import HelpCircle from 'svelte-feather/components/HelpCircle.svelte'
    import { tC } from '../stores/i18n'
    import BrandButton from './BrandButton.svelte'
    import ModalDialog from './ModalDialog.svelte'

    const dispatch = createEventDispatcher()

    export let article
    export let group
    export let items

  </script>

  <style lang="scss">
    main {
      display: flex;
      flex-direction: row;
      gap: 1rem;

      :global(svg) {
        width: 3rem;
        height: 3rem;
        stroke-width: 1;
      }

      span {
        white-space: pre-wrap;
      }
    }
  </style>

  <ModalDialog closeable on:close>
    <main class="mb-4">
      <HelpCircle/>
      <span>
        {$tC({ de: 'Sie sind dabei, {article} ohne jegliche Auswahl von {group} (z.B. {items}, ...) zu bestellen. Ist das so in Ordnung?', en: 'You are about to order {article} without any selection of {group} (such as {items}, ...). Is this correct?' }, { article, group, items: items.join(', ') })}
      </span>
    </main>

    <div class="has-text-right">
        <BrandButton class="is-primary" on:click={() => dispatch('close', true)}>{$tC({ de: 'Ja, ohne {group} bestellen', en: 'Yes, order without any {group}' }, { group })}</BrandButton>
        <BrandButton on:click={() => dispatch('close', false)}>{$tC({ de: 'Nein, {group} hinzufügen', en: 'No, add {group}' }, { group })}</BrandButton>
    </div>
  </ModalDialog>
